export const CREATE_REPORT_REQUEST = "CREATE_REPORT_REQUEST";
export const CREATE_REPORT_SUCCESS = "CREATE_REPORT_SUCCESS";
export const CREATE_REPORT_FAILED = "CREATE_REPORT_FAILED";

export const GET_ALL_REPORTS_REQUEST = "GET_ALL_REPORTS_REQUEST";
export const GET_ALL_REPORTS_SUCCESS = "GET_ALL_REPORTS_SUCCESS";
export const GET_ALL_REPORTS_FAILED = "GET_ALL_REPORTS_FAILED";

export const RESOLVE_REPORT_REQUEST = "RESOLVE_REPORT_REQUEST";
export const RESOLVE_REPORT_SUCCESS = "RESOLVE_REPORT_SUCCESS";
export const RESOLVE_REPORT_FAILED = "RESOLVE_REPORT_FAILED";

export const DELETE_REPORT_REQUEST = "DELETE_REPORT_REQUEST";
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS";
export const DELETE_REPORT_FAILED = "DELETE_REPORT_FAILED";

export const DELETE_REPORTED_OBJECT_REQUEST = "DELETE_REPORTED_OBJECT_REQUEST";
export const DELETE_REPORTED_OBJECT_SUCCESS = "DELETE_REPORTED_OBJECT_SUCCESS";
export const DELETE_REPORTED_OBJECT_FAILED = "DELETE_REPORTED_OBJECT_FAILED";
