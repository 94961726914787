export const FETCH_PURCHASE_HISTORY_REQUEST = "FETCH_PURCHASE_HISTORY_REQUEST";
export const FETCH_PURCHASE_HISTORY_SUCCESS = "FETCH_PURCHASE_HISTORY_SUCCESS";
export const FETCH_PURCHASE_HISTORY_FAILURE = "FETCH_PURCHASE_HISTORY_FAILURE";

export const FETCH_TOTAL_SALES_REQUEST = "FETCH_TOTAL_SALES_REQUEST";
export const FETCH_TOTAL_SALES_SUCCESS = "FETCH_TOTAL_SALES_SUCCESS";
export const FETCH_TOTAL_SALES_FAILURE = "FETCH_TOTAL_SALES_FAILURE";

export const FETCH_TOTAL_PURCHASES_REQUEST = "FETCH_TOTAL_PURCHASES_REQUEST";
export const FETCH_TOTAL_PURCHASES_SUCCESS = "FETCH_TOTAL_PURCHASES_SUCCESS";
export const FETCH_TOTAL_PURCHASES_FAILURE = "FETCH_TOTAL_PURCHASES_FAILURE";

export const FETCH_SALES_PER_USER_REQUEST = "FETCH_SALES_PER_USER_REQUEST";
export const FETCH_SALES_PER_USER_SUCCESS = "FETCH_SALES_PER_USER_SUCCESS";
export const FETCH_SALES_PER_USER_FAILURE = "FETCH_SALES_PER_USER_FAILURE";
