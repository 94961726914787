export const GET_ALL_CREDIT_PACKAGES_REQUEST = "GET_ALL_CREDIT_PACKAGES_REQUEST";
export const GET_ALL_CREDIT_PACKAGES_SUCCESS = "GET_ALL_CREDIT_PACKAGES_SUCCESS";
export const GET_ALL_CREDIT_PACKAGES_FAILURE = "GET_ALL_CREDIT_PACKAGES_FAILURE";

export const GET_ACTIVE_CREDIT_PACKAGES_REQUEST = "GET_ACTIVE_CREDIT_PACKAGES_REQUEST";
export const GET_ACTIVE_CREDIT_PACKAGES_SUCCESS = "GET_ACTIVE_CREDIT_PACKAGES_SUCCESS";
export const GET_ACTIVE_CREDIT_PACKAGES_FAILURE = "GET_ACTIVE_CREDIT_PACKAGES_FAILURE";

export const GET_CREDIT_PACKAGE_BY_ID_REQUEST = "GET_CREDIT_PACKAGE_BY_ID_REQUEST";
export const GET_CREDIT_PACKAGE_BY_ID_SUCCESS = "GET_CREDIT_PACKAGE_BY_ID_SUCCESS";
export const GET_CREDIT_PACKAGE_BY_ID_FAILURE = "GET_CREDIT_PACKAGE_BY_ID_FAILURE";

export const CREATE_CREDIT_PACKAGE_REQUEST = "CREATE_CREDIT_PACKAGE_REQUEST";
export const CREATE_CREDIT_PACKAGE_SUCCESS = "CREATE_CREDIT_PACKAGE_SUCCESS";
export const CREATE_CREDIT_PACKAGE_FAILURE = "CREATE_CREDIT_PACKAGE_FAILURE";

export const UPDATE_CREDIT_PACKAGE_REQUEST = "UPDATE_CREDIT_PACKAGE_REQUEST";
export const UPDATE_CREDIT_PACKAGE_SUCCESS = "UPDATE_CREDIT_PACKAGE_SUCCESS";
export const UPDATE_CREDIT_PACKAGE_FAILURE = "UPDATE_CREDIT_PACKAGE_FAILURE";

export const DELETE_CREDIT_PACKAGE_REQUEST = "DELETE_CREDIT_PACKAGE_REQUEST";
export const DELETE_CREDIT_PACKAGE_SUCCESS = "DELETE_CREDIT_PACKAGE_SUCCESS";
export const DELETE_CREDIT_PACKAGE_FAILURE = "DELETE_CREDIT_PACKAGE_FAILURE";

export const SEARCH_CREDIT_PACKAGES_BY_NAME_REQUEST = "SEARCH_CREDIT_PACKAGES_BY_NAME_REQUEST";
export const SEARCH_CREDIT_PACKAGES_BY_NAME_SUCCESS = "SEARCH_CREDIT_PACKAGES_BY_NAME_SUCCESS";
export const SEARCH_CREDIT_PACKAGES_BY_NAME_FAILURE = "SEARCH_CREDIT_PACKAGES_BY_NAME_FAILURE";

export const GET_CREDIT_PACKAGES_BY_PRICE_REQUEST = "GET_CREDIT_PACKAGES_BY_PRICE_REQUEST";
export const GET_CREDIT_PACKAGES_BY_PRICE_SUCCESS = "GET_CREDIT_PACKAGES_BY_PRICE_SUCCESS";
export const GET_CREDIT_PACKAGES_BY_PRICE_FAILURE = "GET_CREDIT_PACKAGES_BY_PRICE_FAILURE";

export const GET_CREDIT_PACKAGES_SORTED_BY_CREDIT_REQUEST = "GET_CREDIT_PACKAGES_SORTED_BY_CREDIT_REQUEST";
export const GET_CREDIT_PACKAGES_SORTED_BY_CREDIT_SUCCESS = "GET_CREDIT_PACKAGES_SORTED_BY_CREDIT_SUCCESS";
export const GET_CREDIT_PACKAGES_SORTED_BY_CREDIT_FAILURE = "GET_CREDIT_PACKAGES_SORTED_BY_CREDIT_FAILURE";

export const ACTIVATE_CREDIT_PACKAGE_REQUEST = "ACTIVATE_CREDIT_PACKAGE_REQUEST";
export const ACTIVATE_CREDIT_PACKAGE_SUCCESS = "ACTIVATE_CREDIT_PACKAGE_SUCCESS";
export const ACTIVATE_CREDIT_PACKAGE_FAILURE = "ACTIVATE_CREDIT_PACKAGE_FAILURE";

export const DEACTIVATE_CREDIT_PACKAGE_REQUEST = "DEACTIVATE_CREDIT_PACKAGE_REQUEST";
export const DEACTIVATE_CREDIT_PACKAGE_SUCCESS = "DEACTIVATE_CREDIT_PACKAGE_SUCCESS";
export const DEACTIVATE_CREDIT_PACKAGE_FAILURE = "DEACTIVATE_CREDIT_PACKAGE_FAILURE";
