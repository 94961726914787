export const FETCH_POSTS_REQUEST = "FETCH_POSTS_REQUEST";
export const FETCH_POSTS_SUCCESS = "FETCH_POSTS_SUCCESS";
export const FETCH_POSTS_FAILURE = "FETCH_POSTS_FAILURE";

export const FETCH_POSTS_BY_ID_REQUEST = "FETCH_POSTS_BY_ID_REQUEST";
export const FETCH_POSTS_BY_ID_SUCCESS = "FETCH_POSTS_BY_ID_SUCCESS";
export const FETCH_POSTS_BY_ID_FAILURE = "FETCH_POSTS_BY_ID_FAILURE";

export const FETCH_POSTS_BY_USER_REQUEST = "FETCH_POSTS_BY_USER_REQUEST";
export const FETCH_POSTS_BY_USER_SUCCESS = "FETCH_POSTS_BY_USER_SUCCESS";
export const FETCH_POSTS_BY_USER_FAILURE = "FETCH_POSTS_BY_USER_FAILURE";

export const ADD_POST_REQUEST = "ADD_POST_REQUEST";
export const ADD_POST_SUCCESS = "ADD_POST_SUCCESS";
export const ADD_POST_FAILURE = "ADD_POST_FAILURE";

export const UPDATE_POST_REQUEST = "UPDATE_POST_REQUEST";
export const UPDATE_POST_SUCCESS = "UPDATE_POST_SUCCESS";
export const UPDATE_POST_FAILURE = "UPDATE_POST_FAILURE";

export const DELETE_POST_REQUEST = "DELETE_POST_REQUEST";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_FAILURE = "DELETE_POST_FAILURE";

export const LIKE_POST_SUCCESS = "LIKE_POST_SUCCESS";

export const CREATE_POST_COMMENT_REQUEST = "CREATE_POST_COMMENT_REQUEST";
export const CREATE_POST_COMMENT_SUCCESS = "CREATE_POST_COMMENT_SUCCESS";
export const CREATE_POST_COMMENT_FAILED = "CREATE_POST_COMMENT_FAILED";

export const CREATE_REPLY_POST_COMMENT_REQUEST = "CREATE_REPLY_POST_COMMENT_REQUEST";
export const CREATE_REPLY_POST_COMMENT_SUCCESS = "CREATE_REPLY_POST_COMMENT_SUCCESS";
export const CREATE_REPLY_POST_COMMENT_FAILED = "CREATE_REPLY_POST_COMMENT_FAILED";

export const EDIT_POST_COMMENT_REQUEST = "EDIT_POST_COMMENT_REQUEST";
export const EDIT_POST_COMMENT_SUCCESS = "EDIT_POST_COMMENT_SUCCESS";
export const EDIT_POST_COMMENT_FAILED = "EDIT_POST_COMMENT_FAILED";

export const DELETE_POST_COMMENT_REQUEST = "DELETE_POST_COMMENT_REQUEST";
export const DELETE_POST_COMMENT_SUCCESS = "DELETE_POST_COMMENT_SUCCESS";
export const DELETE_POST_COMMENT_FAILED = "DELETE_POST_COMMENT_FAILED";
