export const FETCH_USER_CHATS_REQUEST = "FETCH_USER_CHATS_REQUEST";
export const FETCH_USER_CHATS_SUCCESS = "FETCH_USER_CHATS_SUCCESS";
export const FETCH_USER_CHATS_FAILED = "FETCH_USER_CHATS_FAILED";

export const FETCH_CHAT_MESSAGES_REQUEST = "FETCH_CHAT_MESSAGES_REQUEST";
export const FETCH_CHAT_MESSAGES_SUCCESS = "FETCH_CHAT_MESSAGES_SUCCESS";
export const FETCH_CHAT_MESSAGES_FAILED = "FETCH_CHAT_MESSAGES_FAILED";

export const CREATE_CHAT_REQUEST = "CREATE_CHAT_REQUEST";
export const CREATE_CHAT_SUCCESS = "CREATE_CHAT_SUCCESS";
export const CREATE_CHAT_FAILED = "CREATE_CHAT_FAILED";

export const CREATE_MESSAGE_REQUEST = "CREATE_MESSAGE_REQUEST";
export const CREATE_MESSAGE_SUCCESS = "CREATE_MESSAGE_SUCCESS";
export const CREATE_MESSAGE_FAILED = "CREATE_MESSAGE_FAILED";

export const RECEIVE_MESSAGE = "RECEIVE_MESSAGE";

export const SEND_MESSAGE_REQUEST = "SEND_MESSAGE_REQUEST";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAILED = "SEND_MESSAGE_FAILED";
