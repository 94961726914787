export const CHAPTER_UPLOAD_REQUEST = "CHAPTER_UPLOAD_REQUEST";
export const CHAPTER_UPLOAD_SUCCEED = "CHAPTER_UPLOAD_SUCCEED";
export const CHAPTER_UPLOAD_FAILED = "CHAPTER_UPLOAD_FAILED";

export const EDIT_CHAPTER_REQUEST = "EDIT_CHAPTER_REQUEST";
export const EDIT_CHAPTER_SUCCEED = "EDIT_CHAPTER_SUCCEED";
export const EDIT_CHAPTER_FAILED = "EDIT_CHAPTER_FAILED";

export const DELETE_CHAPTER_REQUEST = "DELETE_CHAPTER_REQUEST";
export const DELETE_CHAPTER_SUCCEED = "DELETE_CHAPTER_SUCCEED";
export const DELETE_CHAPTER_FAILED = "DELETE_CHAPTER_FAILED";

export const GET_CHAPTER_REQUEST = "GET_CHAPTER_REQUEST";
export const GET_CHAPTER_SUCCESS = "GET_CHAPTER_SUCCESS";
export const GET_CHAPTER_FAILED = "GET_CHAPTER_FAILED";

export const GET_CHAPTERS_BY_BOOK_REQUEST = "GET_CHAPTERS_BY_BOOK_REQUEST";
export const GET_CHAPTERS_BY_BOOK_SUCCESS = "GET_CHAPTERS_BY_BOOK_SUCCESS";
export const GET_CHAPTERS_BY_BOOK_FAILED = "GET_CHAPTERS_BY_BOOK_FAILED";

export const GET_CHAPTERS_BY_BOOK_AND_LANGUAGE_REQUEST = "GET_CHAPTERS_BY_BOOK_AND_LANGUAGE_REQUEST";
export const GET_CHAPTERS_BY_BOOK_AND_LANGUAGE_SUCCESS = "GET_CHAPTERS_BY_BOOK_AND_LANGUAGE_SUCCESS";
export const GET_CHAPTERS_BY_BOOK_AND_LANGUAGE_FAILED = "GET_CHAPTERS_BY_BOOK_AND_LANGUAGE_FAILED";

export const GET_ALL_CHAPTER_REQUEST = "GET_ALL_CHAPTERS_REQUEST";
export const GET_ALL_CHAPTER_SUCCESS = "GET_ALL_CHAPTERS_SUCCESS";
export const GET_ALL_CHAPTER_FAILED = "GET_ALL_CHAPTERS_FAILED";

export const GET_PROGRESS_REQUEST = "GET_PROGRESSS_REQUEST";
export const GET_PROGRESS_SUCCESS = "GET_PROGRESSS_SUCCESS";
export const GET_PROGRESS_FAILED = "GET_PROGRESSS_FAILED";

export const SAVE_PROGRESS_REQUEST = "SAVE_PROGRESS_REQUEST";
export const SAVE_PROGRESS_SUCCESS = "SAVE_PROGRESS_SUCCESS";
export const SAVE_PROGRESS_FAILED = "SAVE_PROGRESS_FAILED";

export const UNLOCK_CHAPTER_REQUEST = "UNLOCK_CHAPTER_REQUEST";
export const UNLOCK_CHAPTER_SUCCESS = "UNLOCK_CHAPTER_SUCCESS";
export const UNLOCK_CHAPTER_FAILED = "UNLOCK_CHAPTER_FAILED";

export const CREATE_PAYMENT_INTENT_REQUEST = "CREATE_PAYMENT_INTENT_REQUEST";
export const CREATE_PAYMENT_INTENT_SUCCESS = "CREATE_PAYMENT_INTENT_SUCCESS";
export const CREATE_PAYMENT_INTENT_FAILED = "CREATE_PAYMENT_INTENT_FAILED";

export const CLEAR_CHAPTERS = "CLEAR_CHAPTERS";

export const LIKE_CHAPTER_REQUEST = "LIKE_CHAPTER_REQUEST";
export const LIKE_CHAPTER_SUCCESS = "LIKE_CHAPTER_SUCCESS";
export const LIKE_CHAPTER_FAILED = "LIKE_CHAPTER_FAILED";

export const UNLIKE_CHAPTER_REQUEST = "UNLIKE_CHAPTER_REQUEST";
export const UNLIKE_CHAPTER_SUCCESS = "UNLIKE_CHAPTER_SUCCESS";
export const UNLIKE_CHAPTER_FAILED = "UNLIKE_CHAPTER_FAILED";
