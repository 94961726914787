export const GET_BOOK_REQUEST = "GET_BOOK_REQUEST";
export const GET_BOOK_SUCCESS = "GET_BOOK_SUCCESS";
export const GET_BOOK_FAILED = "GET_BOOK_FAILED";

export const GET_ALL_BOOK_REQUEST = "GET_ALL_BOOK_REQUEST";
export const GET_ALL_BOOK_SUCCESS = "GET_ALL_BOOK_SUCCESS";
export const GET_ALL_BOOK_FAILED = "GET_ALL_BOOK_FAILED";

export const GET_FAVOURED_BOOK_REQUEST = "GET_FAVOURED_BOOK_REQUEST";
export const GET_FAVOURED_BOOK_SUCCESS = "GET_FAVOURED_BOOK_SUCCESS";
export const GET_FAVOURED_BOOK_FAILED = "GET_FAVOURED_BOOK_FAILED";

export const GET_BOOKS_BY_AUTHOR_REQUEST = "GET_BOOKS_BY_AUTHOR_REQUEST";
export const GET_BOOKS_BY_AUTHOR_SUCCESS = "GET_BOOKS_BY_AUTHOR_SUCCESS";
export const GET_BOOKS_BY_AUTHOR_FAILED = "GET_BOOKS_BY_AUTHOR_FAILED";

export const GET_LATEST_UPDATE_BOOK_REQUEST = "GET_LATEST_UPDATE_BOOK_REQUEST";
export const GET_LATEST_UPDATE_BOOK_SUCCESS = "GET_LATEST_UPDATE_BOOK_SUCCESS";
export const GET_LATEST_UPDATE_BOOK_FAILED = "GET_LATEST_UPDATE_BOOK_FAILED";

export const GET_AVG_BOOK_RATING_REQUEST = "GET_AVG_BOOK_RATING_REQUEST";
export const GET_AVG_BOOK_RATING_SUCCESS = "GET_AVG_BOOK_RATING_SUCCESS";
export const GET_AVG_BOOK_RATING_FAILED = "GET_AVG_BOOK_RATING_FAILED";

export const GET_BOOK_RATING_BY_USER_REQUEST = "GET_BOOK_RATING_BY_USER_REQUEST";
export const GET_BOOK_RATING_BY_USER_SUCCESS = "GET_BOOK_RATING_BY_USER_SUCCESS";
export const GET_BOOK_RATING_BY_USER_FAILED = "GET_BOOK_RATING_BY_USER_FAILED";

export const GET_READING_PROGRESSES_BY_BOOK_REQUEST = "GET_READING_PROGRESSES_BY_BOOK_REQUEST";
export const GET_READING_PROGRESSES_BY_BOOK_SUCCESS = "GET_READING_PROGRESSES_BY_BOOK_SUCCESS";
export const GET_READING_PROGRESSES_BY_BOOK_FAILED = "GET_READING_PROGRESSES_BY_BOOK_FAILED";

export const GET_FEATURED_BOOKS_REQUEST = "GET_FEATURED_BOOKS_REQUEST";
export const GET_FEATURED_BOOKS_SUCCESS = "GET_FEATURED_BOOKS_SUCCESS";
export const GET_FEATURED_BOOKS_FAILED = "GET_FEATURED_BOOKS_FAILED";

export const GET_TRENDING_BOOKS_REQUEST = "GET_TRENDING_BOOKS_REQUEST";
export const GET_TRENDING_BOOKS_SUCCESS = "GET_TRENDING_BOOKS_SUCCESS";
export const GET_TRENDING_BOOKS_FAILED = "GET_TRENDING_BOOKS_FAILED";

export const GET_RELATED_BOOKS_REQUEST = "GET_RELATED_BOOKS_REQUEST";
export const GET_RELATED_BOOKS_SUCCESS = "GET_RELATED_BOOKS_SUCCESS";
export const GET_RELATED_BOOKS_FAILED = "GET_RELATED_BOOKS_FAILED";

export const BOOK_UPLOAD_REQUEST = "BOOK_UPLOAD_REQUEST";
export const BOOK_UPLOAD_SUCCEED = "BOOK_UPLOAD_SUCCEED";
export const BOOK_UPLOAD_FAILED = "BOOK_UPLOAD_FAILED";

export const BOOK_EDIT_REQUEST = "BOOK_EDIT_REQUEST";
export const BOOK_EDIT_SUCCEED = "BOOK_EDIT_SUCCEED";
export const BOOK_EDIT_FAILED = "BOOK_EDIT_FAILED";

export const BOOK_DELETE_REQUEST = "BOOK_DELETE_REQUEST";
export const BOOK_DELETE_SUCCEED = "BOOK_DELETE_SUCCEED";
export const BOOK_DELETE_FAILED = "BOOK_DELETE_FAILED";

export const FOLLOW_BOOK_REQUEST = "FOLLOW_BOOK_REQUEST";
export const FOLLOW_BOOK_SUCCESS = "FOLLOW_BOOK_SUCCESS";
export const FOLLOW_BOOK_FAILED = "FOLLOW_BOOK_FAILED";

export const RATING_BOOK_REQUEST = "RATING_BOOK_REQUEST";
export const RATING_BOOK_SUCCESS = "RATING_BOOK_SUCCESS";
export const RATING_BOOK_FAILED = "RATING_BOOK_FAILED";

export const SEARCH_BOOK_REQUEST = "SEARCH_BOOK_REQUEST";
export const SEARCH_BOOK_SUCCESS = "SEARCH_BOOK_SUCCESS";
export const SEARCH_BOOK_FAILED = "SEARCH_BOOK_FAILED";

export const SET_EDIT_CHOICE_REQUEST = "SET_EDIT_CHOICE_REQUEST";
export const SET_EDIT_CHOICE_SUCCESS = "SET_EDIT_CHOICE_SUCCESS";
export const SET_EDIT_CHOICE_FAILED = "SET_EDIT_CHOICE_FAILED";
