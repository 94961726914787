export const GET_TAGS_REQUEST = "GET_TAGS_REQUEST";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";
export const GET_TAGS_FAILED = "GET_TAGS_FAILED";

export const GET_TAG_REQUEST = "GET_TAG_REQUEST";
export const GET_TAG_SUCCESS = "GET_TAG_SUCCESS";
export const GET_TAG_FAILED = "GET_TAG_FAILED";

export const GET_TAGS_BY_BOOK_REQUEST = "GET_TAGS_BY_BOOK_REQUEST";
export const GET_TAGS_BY_BOOK_SUCCESS = "GET_TAGS_BY_BOOK_SUCCESS";
export const GET_TAGS_BY_BOOK_FAILED = "GET_TAGS_BY_BOOK_FAILED";

export const ADD_TAG_REQUEST = "ADD_TAG_REQUEST";
export const ADD_TAG_SUCCESS = "ADD_TAG_SUCCESS";
export const ADD_TAG_FAILED = "ADD_TAG_FAILED";

export const EDIT_TAG_REQUEST = "EDIT_TAG_REQUEST";
export const EDIT_TAG_SUCCESS = "EDIT_TAG_SUCCESS";
export const EDIT_TAG_FAILED = "EDIT_TAG_FAILED";

export const DELETE_TAG_REQUEST = "DELETE_TAG_REQUEST";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const DELETE_TAG_FAILED = "DELETE_TAG_FAILED";
