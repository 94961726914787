export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAILED = "GET_CATEGORIES_FAILED";

export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAILED = "GET_CATEGORY_FAILED";

export const GET_BOOKS_BY_CATEGORY_REQUEST = "GET_BOOKS_BY_CATEGORY_REQUEST";
export const GET_BOOKS_BY_CATEGORY_SUCCESS = "GET_BOOKS_BY_CATEGORY_SUCCESS";
export const GET_BOOKS_BY_CATEGORY_FAILED = "GET_BOOKS_BY_CATEGORY_FAILED";

export const GET_CATEGORY_BY_BOOK_REQUEST = "GET_CATEGORY_BY_BOOK_REQUEST";
export const GET_CATEGORY_BY_BOOK_SUCCESS = "GET_CATEGORY_BY_BOOK_SUCCESS";
export const GET_CATEGORY_BY_BOOK_FAILED = "GET_CATEGORY_BY_BOOK_FAILED";

export const GET_TOP_CATEGORIES_REQUEST = "GET_TOP_CATEGORIES_REQUEST";
export const GET_TOP_CATEGORIES_SUCCESS = "GET_TOP_CATEGORIES_SUCCESS";
export const GET_TOP_CATEGORIES_FAILED = "GET_TOP_CATEGORIES_FAILED";

export const ADD_CATEGORY_REQUEST = "ADD_CATEGORY_REQUEST";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_FAILED = "ADD_CATEGORY_FAILED";

export const EDIT_CATEGORY_REQUEST = "EDIT_CATEGORY_REQUEST";
export const EDIT_CATEGORY_SUCCESS = "EDIT_CATEGORY_SUCCESS";
export const EDIT_CATEGORY_FAILED = "EDIT_CATEGORY_FAILED";

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILED = "DELETE_CATEGORY_FAILED";
